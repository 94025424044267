import { gql } from 'graphql-request';

export const CREATE_CLIENT_FILE_QUERY = gql`
  mutation CreateClientFile(
    $name: String!
    $size: BigInteger
    $originalFilename: String
  ) {
    createClientFile(
      input: { name: $name, size: $size, originalFilename: $originalFilename }
    ) {
      status
      recordUuid
      recordNanoId
      record {
        id
        uuid
        nanoId
        name
        size
        presignedUrl
        presignedUrls
      }
      errors {
        fullMessages
      }
    }
  }
`;
